import React, { useState } from "react";
import axios from "axios";
import { motion } from "framer-motion";
import { RiLoader2Fill } from "react-icons/ri";
import { GrStatusGood } from "react-icons/gr";
import { PiStepsDuotone } from "react-icons/pi";

const ParcelTracking = () => {
  const [trackingNumber, setTrackingNumber] = useState("");
  const [statusHistory, setStatusHistory] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  // Animation configuration for Framer Motion
  const timelineVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0 },
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setError(null);
    setStatusHistory([]);
    setLoading(true);

    try {
      const response = await axios.get(
        `https://goldenairwaybackend-2.onrender.com/api/parcel/status-history/${trackingNumber}`,
        {
          withCredentials: true,
        }
      );

      // Sorting status history in descending order by timestamp
      const sortedStatusHistory = response.data.status.sort(
        (a, b) => new Date(b.timestamp) - new Date(a.timestamp)
      );

      setStatusHistory(sortedStatusHistory);
      setTrackingNumber(""); // Reset input after successful fetch
    } catch (err) {
      setError("Parcel not found or error fetching status.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="container mx-auto p-4 text-center">
      <h1 className="text-2xl font-bold mb-4">Track Your Shipment</h1>
      <p className="text-gray-500 text-sm mb-4">
        Enter a tracking code to get shipment results.
      </p>

      <form onSubmit={handleSubmit} className="max-w-md mx-auto mb-6">
        <label htmlFor="trackingNumber" className="sr-only">
          Search
        </label>
        <div className="relative">
          <input
            type="text"
            id="trackingNumber"
            value={trackingNumber}
            onChange={(e) => setTrackingNumber(e.target.value)}
            className="block w-full p-4 pr-14 text-sm border rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500"
            placeholder="Tracking Number..."
            required
            disabled={loading}
          />
          <button
            type="submit"
            className="absolute right-2 bottom-2 bg-blue-700 hover:bg-blue-800 text-white font-bold px-4 py-2 rounded-lg flex items-center"
            disabled={loading}
          >
            {loading ? (
              <RiLoader2Fill className="animate-spin text-xl" />
            ) : (
              "Track"
            )}
          </button>
        </div>
      </form>

      {error && <p className="text-red-500 mb-4">{error}</p>}

      {statusHistory.length > 0 && (
        <div className="mt-8">
          <section className="py-3">
            <div className="container mx-auto">
              <div className="card shadow-sm border border-gray-200">
                <div className="card-body p-4">
                  <h4 className="text-xl font-semibold text-green-600 flex items-center gap-2 mb-6">
                    <PiStepsDuotone className="text-3xl animate-pulse" />
                    Shipment History
                  </h4>
                  <ol className="relative border-l border-gray-200">
                    {statusHistory.map((status, index) => (
                      <motion.li
                        key={index}
                        initial="hidden"
                        animate="visible"
                        variants={timelineVariants}
                        transition={{ duration: 0.5, delay: index * 0.2 }}
                        className="mb-8 flex items-start"
                      >
                        <span className="absolute w-6 h-6 bg-black rounded-full flex items-center justify-center -left-3 ring-8 ring-white">
                          <GrStatusGood className="text-white text-xl" />
                        </span>
                        <div className="ml-8 p-4 bg-white border border-gray-200 rounded-lg shadow-sm">
                          <time className="text-sm font-medium text-green-600">
                            {new Date(status.timestamp).toLocaleString(
                              "en-US",
                              { timeZone: "UTC" }
                            )}
                          </time>
                          <p className="font-medium text-zinc-600-800 mt-2 uppercase ">
                            <strong className="pe-2" >Status:</strong>
                            {status.status}
                          </p>
                          <p className="text-sm text-gray-500 capitalize">
                            <strong className="pe-2">Location:</strong>
                            {status.location}
                          </p>
                          {status.statusReport && (
                            <p className="text-sm text-gray-600 mt-2 capitalize">
                              <strong className="pe-2">Status Report:</strong>{" "}
                              {status.statusReport}
                            </p>
                          )}
                        </div>
                      </motion.li>
                    ))}
                  </ol>
                </div>
              </div>
            </div>
          </section>
        </div>
      )}
    </div>
  );
};

export default ParcelTracking;
